var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "client-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: { items: _vm.breadcrumbs, large: "" }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10", lg: "8" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "success",
                        icon: "mdi-badge-account",
                        title: _vm.title
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _vm.action.status === 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _vm.action.status === 0
                                        ? _c(
                                            "base-material-alert",
                                            {
                                              attrs: {
                                                color: "warning",
                                                dark: "",
                                                icon: "mdi-zip-disk"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Задача в архиве!\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "action"
                                          ),
                                          rows: "1",
                                          "auto-grow": "",
                                          label: "Задача*"
                                        },
                                        model: {
                                          value: _vm.action.action,
                                          callback: function($$v) {
                                            _vm.$set(_vm.action, "action", $$v)
                                          },
                                          expression: "action.action"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "comment"
                                          ),
                                          rows: "2",
                                          "auto-grow": "",
                                          label: "Комментарий"
                                        },
                                        model: {
                                          value: _vm.action.comment,
                                          callback: function($$v) {
                                            _vm.$set(_vm.action, "comment", $$v)
                                          },
                                          expression: "action.comment"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-switch", {
                                        staticClass: "mx-2",
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "completed"
                                          ),
                                          label: "Выполнена",
                                          "true-value": "1",
                                          "false-value": "0",
                                          "input-value": "1",
                                          "value-comparator": function(a, b) {
                                            return (
                                              a === b ||
                                              parseInt(a) === parseInt(b)
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.action.completed,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.action,
                                              "completed",
                                              $$v
                                            )
                                          },
                                          expression: "action.completed"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "8" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "dateMenu",
                                              attrs: {
                                                "close-on-content-click": false,
                                                "return-value": _vm.date,
                                                transition: "scale-transition",
                                                "min-width": "290px",
                                                "offset-y": ""
                                              },
                                              on: {
                                                "update:returnValue": function(
                                                  $event
                                                ) {
                                                  _vm.date = $event
                                                },
                                                "update:return-value": function(
                                                  $event
                                                ) {
                                                  _vm.date = $event
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                readonly: "",
                                                                value: _vm.formatDate(
                                                                  _vm.action
                                                                    .date
                                                                ),
                                                                label:
                                                                  "Дата исполнения",
                                                                "error-messages": _vm.fieldError(
                                                                  "date"
                                                                ),
                                                                color:
                                                                  "secondary",
                                                                "prepend-icon":
                                                                  "mdi-calendar-outline"
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1471122658
                                              ),
                                              model: {
                                                value: _vm.dateMenu,
                                                callback: function($$v) {
                                                  _vm.dateMenu = $$v
                                                },
                                                expression: "dateMenu"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-date-picker",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    locale: "ru-ru",
                                                    landscape: "",
                                                    scrollable: "",
                                                    "first-day-of-week": "1"
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      _vm.dateMenu = false
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.action.date,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.action,
                                                        "date",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "action.date"
                                                  }
                                                },
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        large: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.dateMenu = false
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Cancel\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            attrs: {
                                              label: "Время исполнения",
                                              "error-messages": _vm.fieldError(
                                                "time"
                                              )
                                            },
                                            model: {
                                              value: _vm.action.time,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.action,
                                                  "time",
                                                  $$v
                                                )
                                              },
                                              expression: "action.time"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "client_id"
                                          ),
                                          label: "Клиент",
                                          items: _vm.clients,
                                          "item-value": "id",
                                          "item-text": "parents",
                                          filter: _vm.customSearch,
                                          disabled:
                                            "client" in this.$route.query
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(data) {
                                                return [
                                                  typeof data.item !== "object"
                                                    ? [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                data.item
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-list-item-avatar",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: "black"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          mdi-account\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .parents
                                                                    )
                                                                  )
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .name
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _vm._v(
                                                                  "#" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .id
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .phone
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .email
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                ]
                                              }
                                            },
                                            {
                                              key: "selection",
                                              fn: function(data) {
                                                return [
                                                  typeof data.item !== "object"
                                                    ? [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                data.item
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .parents
                                                                    )
                                                                  )
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .name
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _vm._v(
                                                                  "#" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .id
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .phone
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .email
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1473335442
                                        ),
                                        model: {
                                          value: _vm.action.client_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.action,
                                              "client_id",
                                              $$v
                                            )
                                          },
                                          expression: "action.client_id"
                                        }
                                      }),
                                      _vm.action.client
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "/client/" +
                                                  _vm.action.client.id,
                                                title:
                                                  "Открыть клиента в новой вкладке",
                                                target: "_blank"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "break-chip",
                                                  attrs: {
                                                    color: "pink lighten-5",
                                                    "x-small": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    #" +
                                                      _vm._s(
                                                        _vm.action.client.id
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.action.client
                                                          .parents
                                                          ? _vm.action.client
                                                              .parents
                                                          : _vm.action.client
                                                              .name
                                                          ? _vm.action.client
                                                              .name
                                                          : ""
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        right: "",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    [_vm._v("mdi-open-in-new")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "teacher_id"
                                          ),
                                          label: "Преподаватель",
                                          items: _vm.teachers,
                                          "item-value": "id",
                                          "item-text": "name",
                                          filter: _vm.customSearch,
                                          disabled:
                                            "teacher" in this.$route.query
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(data) {
                                                return [
                                                  typeof data.item !== "object"
                                                    ? [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                data.item
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-list-item-avatar",
                                                          [
                                                            data.item.photo
                                                              ? _c("v-img", {
                                                                  staticClass:
                                                                    "grey lighten-2",
                                                                  attrs: {
                                                                    src: _vm.imageLink(
                                                                      data.item
                                                                        .id
                                                                    ),
                                                                    "lazy-src": _vm.imageLink(
                                                                      data.item
                                                                        .id
                                                                    ),
                                                                    "aspect-ratio":
                                                                      "1.5",
                                                                    width:
                                                                      "100px"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "placeholder",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "fill-height ma-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center",
                                                                                  justify:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-progress-circular",
                                                                                  {
                                                                                    attrs: {
                                                                                      indeterminate:
                                                                                        "",
                                                                                      color:
                                                                                        "grey lighten-5"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                })
                                                              : _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "black"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          mdi-account\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .name
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _vm._v(
                                                                  "#" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .id
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .phone
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .email
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                ]
                                              }
                                            },
                                            {
                                              key: "selection",
                                              fn: function(data) {
                                                return [
                                                  typeof data.item !== "object"
                                                    ? [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                data.item
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .name
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _vm._v(
                                                                  "#" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .id
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .phone
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .email
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2478411061
                                        ),
                                        model: {
                                          value: _vm.action.teacher_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.action,
                                              "teacher_id",
                                              $$v
                                            )
                                          },
                                          expression: "action.teacher_id"
                                        }
                                      }),
                                      _vm.action.teacher
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "/teacher/" +
                                                  _vm.action.teacher.id,
                                                title:
                                                  "Открыть преподавателя в новой вкладке",
                                                target: "_blank"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "break-chip",
                                                  attrs: {
                                                    color: "purple lighten-5",
                                                    "x-small": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    #" +
                                                      _vm._s(
                                                        _vm.action.teacher.id
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.action.teacher.name
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        right: "",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    [_vm._v("mdi-open-in-new")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pl-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "success",
                                            "min-width": "100"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.saveAction()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Сохранить\n                "
                                          )
                                        ]
                                      ),
                                      _vm.action.status === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Переместить в архив\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.action.status === 0 &&
                                      _vm.action.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.unarchive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Вернуть из архива\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.action.status === 0 &&
                                      _vm.action.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-1",
                                              attrs: {
                                                color: "error",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.confirmDialog = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Удалить\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить задачу?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Задача будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeAction(_vm.action.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }