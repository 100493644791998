<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="breadcrumbs"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="8"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="title"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row
              justify="center"
            >
              <v-col
                v-if="action.status === 0"
                cols="12"
                md="12"
              >
                <base-material-alert
                  v-if="action.status === 0"
                  color="warning"
                  dark
                  icon="mdi-zip-disk"
                >
                  Задача в архиве!
                </base-material-alert>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-textarea
                    v-model="action.action"
                    :error-messages="fieldError('action')"
                    rows="1"
                    auto-grow
                    label="Задача*"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-textarea
                    v-model="action.comment"
                    :error-messages="fieldError('comment')"
                    rows="2"
                    auto-grow
                    label="Комментарий"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-switch
                    v-model="action.completed"
                    :error-messages="fieldError('completed')"
                    class="mx-2"
                    label="Выполнена"
                    true-value="1"
                    false-value="0"
                    input-value="1"
                    :value-comparator="(a, b) => a === b || parseInt(a) === parseInt(b)"
                  />
                </v-col>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-menu
                      ref="dateMenu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(action.date)"
                          label="Дата исполнения"
                          :error-messages="fieldError('date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="action.date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        scrollable
                        first-day-of-week="1"
                        @input="dateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="dateMenu = false"
                        >
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="action.time"
                      v-facade="'##:##'"
                      label="Время исполнения"
                      :error-messages="fieldError('time')"
                    />
                  </v-col>
                </v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-autocomplete
                    v-model="action.client_id"
                    :error-messages="fieldError('client_id')"
                    label="Клиент"
                    :items="clients"
                    item-value="id"
                    item-text="parents"
                    :filter="customSearch"
                    :disabled="'client' in this.$route.query"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <v-icon color="black">
                            mdi-account
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title><b>{{ data.item.parents }}</b> {{ data.item.name }}</v-list-item-title>
                          <v-list-item-subtitle>#{{ data.item.id }} {{ data.item.phone }} {{ data.item.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>

                    <template v-slot:selection="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title><b>{{ data.item.parents }}</b> {{ data.item.name }}</v-list-item-title>
                          <v-list-item-subtitle>#{{ data.item.id }} {{ data.item.phone }} {{ data.item.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <a
                    v-if="action.client"
                    :href="`/client/${action.client.id}`"
                    title="Открыть клиента в новой вкладке"
                    target="_blank"
                    @click.stop=""
                  >
                    <v-chip
                      class="break-chip"
                      color="pink lighten-5"
                      x-small
                    >
                      #{{ action.client.id }} {{ action.client.parents ? action.client.parents : (action.client.name ? action.client.name : '') }}
                      <v-icon
                        right
                        x-small
                      >mdi-open-in-new</v-icon>
                    </v-chip>
                  </a>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-autocomplete
                    v-model="action.teacher_id"
                    :error-messages="fieldError('teacher_id')"
                    label="Преподаватель"
                    :items="teachers"
                    item-value="id"
                    item-text="name"
                    :filter="customSearch"
                    :disabled="'teacher' in this.$route.query"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <v-img
                            v-if="data.item.photo"
                            :src="imageLink(data.item.id)"
                            :lazy-src="imageLink(data.item.id)"
                            aspect-ratio="1.5"
                            width="100px"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                />
                              </v-row>
                            </template>
                          </v-img>
                          <v-icon
                            v-else
                            color="black"
                          >
                            mdi-account
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                          <v-list-item-subtitle>#{{ data.item.id }} {{ data.item.phone }} {{ data.item.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>

                    <template v-slot:selection="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title><b>{{ data.item.name }}</b></v-list-item-title>
                          <v-list-item-subtitle>#{{ data.item.id }} {{ data.item.phone }} {{ data.item.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <a
                    v-if="action.teacher"
                    :href="`/teacher/${action.teacher.id}`"
                    title="Открыть преподавателя в новой вкладке"
                    target="_blank"
                    @click.stop=""
                  >
                    <v-chip
                      class="break-chip"
                      color="purple lighten-5"
                      x-small
                    >
                      #{{ action.teacher.id }} {{ action.teacher.name }}
                      <v-icon
                        right
                        x-small
                      >mdi-open-in-new</v-icon>
                    </v-chip>
                  </a>
                </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    color="success"
                    min-width="100"
                    @click="saveAction()"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    v-if="action.status === 1"
                    color="warning"
                    min-width="100"
                    @click="archive()"
                  >
                    Переместить в архив
                  </v-btn>

                  <v-btn
                    v-if="action.status === 0 && action.id > 0"
                    color="warning"
                    min-width="100"
                    @click="unarchive()"
                  >
                    Вернуть из архива
                  </v-btn>

                  <v-btn
                    v-if="action.status === 0 && action.id > 0"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить задачу?
        </v-card-title>

        <v-card-text>
          Задача будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removeAction(action.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import actionApi from '../services/ActionApi'
  import clientsApi from '../services/ClientsApi'
  import teachersApi from '../services/TeachersApi'

  export default {
    props: ['id'],
    data () {
      return {
        action: {
          id: null,
          action: null,
          client_id: null,
          teacher_id: null,
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        confirmDialog: false,
        courses: [{ id: null, name: ' - не выбрано - ' }],
        currencies: [],

        clients: [],
        teachers: [],

        dateMenu: false,
        date: '',

        client: {
          name: '',
          id: null,
        },

        teacher: {
          name: '',
          id: null,
        },
      }
    },

    computed: {
      title () {
        if (this.action.action) return '#' + this.id + ' ' + this.action.action
        if (this.id === 'create') return 'Добавить задачу'
        return '#' + this.id
      },

      breadcrumbs () {
        let items = [
          { text: 'Ваш центр', to: '/' },
          { text: 'Задачи', to: '/actions' },
        ]
        if (this.client.id) items.push({ text: `#${this.client.id} ${this.client.parents ? this.client.parents : (this.client.name ? this.client.name : '')}`, to: `/client/${this.client.id}` })
        if (this.teacher.id) items.push({ text: `${this.teacher.name}`, to: `/teacher/${this.teacher.id}` })
        items.push({ text: this.title })
        return items
      },
    },

    mounted () {
      document.title = this.title + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.fetchData()
    },

    methods: {
      fetchData () {
        if (this.id === 'create') {
          this.loading = false
          if ('client' in this.$route.query) this.action.client_id = parseInt(this.$route.query.client)
          if ('teacher' in this.$route.query) this.action.teacher_id = parseInt(this.$route.query.teacher)
          this.fetchClientAndTeacher()
        } else {
          actionApi
            .fetchAction(this.id)
            .then(response => {
              this.action = response
              document.title = this.title + ' | ' + process.env.VUE_APP_NAME
              this.fetchClientAndTeacher()
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }

        clientsApi.fetchClients()
          .then(response => {
            this.clients = response
            this.loading = false
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
          })

        teachersApi.fetchTeachers()
          .then(response => {
            this.teachers = response
            this.loading = false
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      fetchClientAndTeacher () {
        if (this.action.client_id) {
          clientsApi
            .fetchClient(this.action.client_id)
            .then(response => {
              this.client = response
              this.loading = false
            })
            .catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }

        if (this.action.teacher_id) {
          teachersApi
            .fetchTeacher(this.action.teacher_id)
            .then(response => {
              this.teacher = response
              this.loading = false
            })
            .catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }
      },

      back () {
        this.$router.push('/')
      },

      saveAction () {
        if (this.action.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          actionApi
            .updateAction(this.action)
            .then(response => {
              this.action = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          actionApi
            .createAction(this.action)
            .then(response => {
              this.action = response
              this.$router.push('/action/' + this.action.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },

      removeAction () {
        this.confirmDialog = false
        this.loading = true
        actionApi
          .deleteAction(this.action.id)
          .then(response => {
            // this.template = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      archive () {
        this.action.status = 0
        this.saveAction()
      },
      unarchive () {
        this.action.status = 1
        this.saveAction()
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },
      imageLink (id) {
        return process.env.VUE_APP_OLD_URL + 't/100/100/' + id
      },
      customSearch (item, queryText, itemText) {
        const data =
          ('name' in item && item.name ? item.name.toLowerCase() : '') +
          ('parents' in item && item.parents ? item.parents.toLowerCase() : '') +
          ('phone' in item && item.phone ? item.phone.toLowerCase() : '') +
          ('email' in item && item.email ? item.email.toLowerCase() : '')
        const searchText = queryText.toLowerCase()
        return data.indexOf(searchText) > -1
      },
    },
  }
</script>
